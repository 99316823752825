<template>
	<PageLayout hide-back-button>
		<div class="card">
			<h1 class="container-header no-mr">{{ $t('deposit.monopoly-amount-confirmation') }} {{ amount }} {{ '$' }}</h1>
			<Spacer height size="xl" />
			<div class="buttons">
				<div class="buttons-content">
					<CButton full-width primary @click="confirmPayment">
						{{ $t('actions.confirm') }}
					</CButton>
					<CButton full-width secondary @click="cancelPayment">
						{{ $t('actions.cancel') }}
					</CButton>
				</div>
			</div>
		</div>
	</PageLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import CButton from '@/shared/cbutton/CButton';
import Spacer from '@/shared/spacer/Spacer.vue';
import apiClient from '../../api';
const PageLayout = () => import('../page-layout/PageLayout');

export default {
	name: 'MonopolyMoneyConfirmation',
	components: {
		PageLayout,
		CButton,
		Spacer,
	},
	props: {
		amount: {
			type: Number,
			required: true,
		},
		transactionId: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapGetters(['isEmbedded']),
	},
	methods: {
		navigateTo(link, params) {
			const queryString = new URLSearchParams(params).toString();
			window.location.href = `${link}?${queryString}`;
		},
		async confirmTransaction(transactionId) {
			await apiClient.monopolyConfirmTransaction(transactionId);
		},
		async cancelTransaction(transactionId) {
			await apiClient.monopolyCancelTransaction(transactionId);
		},
		async confirmPayment() {
			await this.confirmTransaction(this.transactionId);
			this.navigateTo(`/payments/deposit/${this.$route.params.walletId}/confirmation`, { status: 'SUCCESS' });
		},
		async cancelPayment() {
			await this.cancelTransaction(this.transactionId);
			this.navigateTo('/');
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.card {
	@include card;
	flex-grow: 1;
	min-width: 100%;
	padding: 40px 10px;
}

.amount-display {
	text-align: center;
	font-size: $label-xl;
}

.no-mr {
	margin: 0;
}

.buttons {
	display: flex;
	justify-content: center;
	margin-bottom: 5px;

	&-content {
		display: flex;
		justify-content: center;
		gap: 5px;
		width: 60%;

		@include max-screen($md) {
			flex-direction: column;
			width: 100%;
		}
	}
}
</style>
